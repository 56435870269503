import { render, staticRenderFns } from "./ShwePyae.vue?vue&type=template&id=284cb99e&scoped=true&"
import script from "./ShwePyae.vue?vue&type=script&lang=js&"
export * from "./ShwePyae.vue?vue&type=script&lang=js&"
import style0 from "./ShwePyae.vue?vue&type=style&index=0&id=284cb99e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284cb99e",
  null
  
)

export default component.exports