<template>
    <section class="profileLayout">
        <reward-side-bar />

        <div class="container-fluid my-5 px-4 px-xl-5" v-if="user">
            <point-section
                :points="user.product_point"
                type="ROYALTY REWARD"
                :img="img_path"
                :rewards="rewardHistories"
            />

            <div class="row">
                <div class="col-md-5">
                    <reward-section
                        v-if="shwePyaeRewards && shwePyaeRewards.length > 0"
                        :rewards="shwePyaeRewards"
                        :userPoints="user.product_point"
                    />
                    <p v-else>No Reward Package Found!</p>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5">
                    <leader-board
                        :data="getAllLeaderBoards"
                        type="shwe"
                        :isLoading="isLoad"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import RewardSideBar from '@/components/reusable/RewardSideBar.vue';
import PointSection from '@/components/reusable/PointSection.vue';
import RewardSection from '@/components/reward/RewardSection.vue';
import LeaderBoard from '@/components/reward/LeaderBoard.vue';

import LeaderBoardAllGql from '@/graphql/queries/point/LeaderBoardAll.gql';
import RedeemListGql from '@/graphql/queries/point/RedeemList.gql';

import { mapGetters } from 'vuex';

export default {
    components: {
        'reward-side-bar': RewardSideBar,
        'point-section': PointSection,
        'reward-section': RewardSection,
        'leader-board': LeaderBoard,
    },

    data() {
        return {
            leadersData: [],
            isLoad: false,
            img_path: require('../../assets/img/Royalty.png'),
        };
    },

    mounted() {
        // this.getLeaderBoard();
        this.getRedeemList();
    },

    computed: {
        ...mapGetters({
            getAllLeaderBoards: 'point/getAllLeaderboards',
            user: 'auth/getUser',
            shwePyaeRewards: 'point/getShwePyaeRewardList',
            rewardHistories: 'point/getRedeemLoyalHistories',
        }),
    },

    watch: {
        user() {
            if (this.user && this.user.id) {
                this.getLeaderBoard(this.user.id);
            }
        },
    },

    methods: {
        getLeaderBoard(id) {
            this.isLoad = true;
            this.$apollo
                .query({
                    query: LeaderBoardAllGql,

                    variables: {
                        field_name: 'product_point',
                    },
                })
                .then(response => {
                    // console.log(this.user, 'user');
                    this.leadersData = response.data.leaderboardsall;
                    const getFiveItems =
                        this.leadersData.length > 5
                            ? this.leadersData.slice(0, 5)
                            : this.leadersData;

                    const getMyPointIndex = this.leadersData.findIndex(
                        leader => leader.id == id,
                    );

                    this.$store.commit(
                        'point/ADD_MY_POINT_PLACE_INDEX',
                        getMyPointIndex,
                    );

                    this.$store.commit(
                        'point/ADD_LEADERBOARDS_ALL',
                        getFiveItems,
                    );
                    this.isLoad = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoad = false;
                });
        },

        getRedeemList() {
            this.$apollo
                .query({
                    query: RedeemListGql,

                    variables: {
                        point_type: 'shwepyae',
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'point/ADD_SHWE_PYAE_REWARDS',
                        response.data.redeemCardList,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/sass/components/profileLayout.scss';
</style>
